import { LoginService } from "src/app/login/login.service";
import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, throwError } from "rxjs";
import { catchError, filter, finalize, switchMap, take } from "rxjs";
import { Observable } from "rxjs";
import { LocalStorageService } from "src/app/utils/localstorage.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  isRefreshing = false;
  refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private loginService: LoginService,
    private localStorage: LocalStorageService
  ) {}
  // intercept any http call done by the httpClient
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const jwtToken = this.loginService.get_access_token();
    const refreshToken = this.loginService.get_refresh_token();
    const data = this.localStorage.get("data");

    return jwtToken != null
      ? (next.handle(this.addToken(request, jwtToken)).pipe(
          catchError((httpErrorResponse: HttpErrorResponse) => {
            // Customize as you wish:
            const newHttpErrorResponse = new HttpErrorResponse({
              error: httpErrorResponse.error,
              headers: httpErrorResponse.headers,
              status: httpErrorResponse.status,
              statusText: httpErrorResponse.statusText,
              url: httpErrorResponse.url,
            });

            if (newHttpErrorResponse.status === 401) {
              console.log("401");
              console.log(newHttpErrorResponse);
              if (newHttpErrorResponse.url.indexOf("/token/refresh") != -1) {
                this.loginService.logoutWithoutBackend();
                // return next.handle(newHttpErrorResponse);
              } else {
                this.isRefreshing = false;
                return next.handle(this.addToken(request, jwtToken));
              }
            }

            if (newHttpErrorResponse.status === 403) {
              // refresh token and replay last request if successful

              if (!this.isRefreshing) {
                this.isRefreshing = true;

                // Reset here so that the following requests wait until the token
                // comes back from the refreshToken call.
                this.refreshTokenSubject.next(null);

                console.log("Refreshing token because 403");

                return this.loginService
                  .refreshToken(refreshToken, data["uuid"])
                  .pipe(
                    switchMap((res) => {
                      console.log("refresh token", res);
                      let remember = this.localStorage.get("remember");
                      if (remember) {
                        this.localStorage.set("access_token", res.access);
                        this.localStorage.set("refresh_token", res.refresh);
                      } else {
                        sessionStorage.setItem("access_token", res.access);
                        sessionStorage.setItem("refresh_token", res.refresh);
                      }
                      this.refreshTokenSubject.next(res.access);

                      return next.handle(this.addToken(request, res.access));
                    }),
                    catchError((error) => {
                      console.log("error refreshing", error);
                      // If there is an exception calling 'refreshToken', bad news so logout.
                      if (error.url.indexOf("/token/refresh") != -1) {
                        this.loginService.logoutWithoutBackend();
                        return error;
                      } else {
                        this.isRefreshing = false;
                        return next.handle(error);
                      }
                    }),
                    finalize(() => {
                      this.isRefreshing = false;
                      return next.handle(this.addToken(request, jwtToken));
                    })
                  );
              } else {
                return this.refreshTokenSubject.pipe(
                  filter((token) => token != null),
                  take(1),
                  switchMap((token: any) => {
                    return next.handle(this.addToken(request, token));
                  })
                );
              }

              // auto logout if 403 response returned from api
              // this.loginService.logout();
            }

            if (
              newHttpErrorResponse.status === 400 ||
              newHttpErrorResponse.status === 404 ||
              newHttpErrorResponse.status === 406 ||
              newHttpErrorResponse.status === 500
            ) {
              return throwError(httpErrorResponse);
            }

            // return throwError(httpErrorResponse);
          })
        ) as Observable<HttpEvent<any>>)
      : next.handle(request);
  }

  private addToken(request: HttpRequest<any>, token: any) {
    return request.clone({
      setHeaders: { Authorization: `JWT ${token}` },
    });
  }
}

import { LoginService } from "./../login/login.service";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "src/app/utils/localstorage.service";
import { environment } from "../../environments/environment";
import { Intercom } from "@supy-io/ngx-intercom";
import { ConversationsService } from "../dashboard/conversations/conversations.service";

/**
Class used to extend the CanActivate method. It's used to run a piece of code
before a route to check if it can be activated or not
*/
@Injectable()
export class AuthGuard {
  is_intercom_booted = false;

  constructor(
    public router: Router,
    public localStorage: LocalStorageService,
    public intercom: Intercom,
    public loginService: LoginService,
    public conversationsService: ConversationsService
  ) {}

  /**
   * Checks if user has access_token in localStorage.
   */
  async canActivate() {
    if (
      this.loginService.get_access_token() !== undefined &&
      this.loginService.get_access_token() != null
    ) {
      await this.loginService.loginToFirestore(false);
      if (!this.is_intercom_booted) {
        this.is_intercom_booted = true;

        let intercomSettings = {
          app_id: "wqv1cgow",
          name: `${this.localStorage.get("user")["first_name"]} ${
            this.localStorage.get("user")["last_name"]
          }`,
          user_hash: this.localStorage.get("user")["user_hash"],
          email: this.localStorage.get("user")["email"],
          userid: this.localStorage.get("user")["id"],
          created_at: new Date(
            this.localStorage.get("data")["created"]
          ).getTime(),
          custom_data: {
            user_company: this.localStorage.get("company")["is_accounting"],
            company_name: this.localStorage.get("company")["name"],
            accounting_company_name:
              this.localStorage.get("company")["accounting_company"] != null
                ? this.localStorage.get("company")["accounting_company"]["name"]
                : null,
            user_type: this.localStorage.get("data")["user_type"],
            user_system: environment.production ? "prod" : "qa",
            is_owner:
              this.localStorage.get("user")["id"] ==
              this.localStorage.get("company")["owner"]
                ? true
                : false,
            first_name: this.localStorage.get("user")["first_name"],
            last_name: this.localStorage.get("user")["last_name"],
          },
        };

        this.intercom.boot(intercomSettings);
      }

      return true;
    }

    // not logged in so redirect to login page

    this.router.navigate(["/login"]);
    return false;
  }
}
